<template>
  <nav
    ref="sidebar"
    class="sm-sidebar-menu"
    :class="sidebarOpenClass"
  >
    <div
      class="sm-sidebar-menu__logo sm-sidebar-menu__logo-full"
      :class="logoVisibleClass"
    >
      <slot name="logo-full"></slot>
    </div>

    <div
      v-if="!modelValue"
      class="sm-sidebar-menu__logo"
    >
      <slot name="logo-mini"></slot>
    </div>

    <sm-icon-button
      class="sm-sidebar-menu__toggle-button"
      name="ArrowForward"
      :size="toggleIconSize"
      @click="handleToggleMenu"
    />
    <slot name="above-menu"></slot>
    <div class="sm-sidebar-menu__list-wrapper">
      <ul class="sm-sidebar-menu__list">
        <li
          v-for="(item, index) in menu"
          :key="index"
          class="sm-sidebar-menu__item"
        >
          <sm-tooltip
            :text="getTooltipText(item)"
            position="right"
            align="middle"
            :offset="20"
          >
            <router-link
              :to="item.route"
              active-class="sm-sidebar-menu__link--active"
              :class="getLinkClassList(item.root)"
              @click.native="handleCloseMenu"
            >
              <div
                v-if="showIcons"
                class="sm-sidebar-menu__link-icon"
              >
                <sm-icon
                  v-if="item.icon"
                  :name="item.icon"
                  :size="menuIconSize"
                />
              </div>
              <span
                class="sm-sidebar-menu__link-title"
                :class="expandedContentClass"
              >
                {{ item.title }}
              </span>
            </router-link>
          </sm-tooltip>
        </li>
      </ul>
    </div>
    <slot name="bottom"></slot>
  </nav>
</template>

<script lang="ts" setup>
// Modules
import { ref, computed, watch, toRefs } from 'vue';
// Composables
import { useRoute } from 'vue-router';
// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';
import SmIconButton from '@/components/common/buttons/SmIconButton.vue';
import SmTooltip from '@/components/common/SmTooltip.vue';
// Types
import { ISidebarMenuProps, ISidebarItem } from '@/types/components/sidebar';
import { Class } from '@/types/common';

// Composable functions
const route = useRoute();

// Model
const modelValue = defineModel<boolean>({ default: false });

// Props
const props = withDefaults(defineProps<ISidebarMenuProps>(), {
  menu: () => [],
  menuIconSize: '24',
  toggleIconSize: '24',
  showIcons: true,
  closeAfterTransition: true,
});

const {
  menu,
  menuIconSize,
  toggleIconSize,
  showIcons,
  closeAfterTransition,
} = toRefs(props);

// Emits
const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

// Data
const isExpandedContentVisible = ref<boolean>(modelValue.value);

// Computed
const expandedContentClass = computed((): Class => ([
  { 'sm-sidebar-menu__link-title--visible': isExpandedContentVisible.value },
  { 'sm-sidebar-menu__link-title--no-icon': !showIcons.value }
]));

const sidebarOpenClass = computed((): Class => ([
  { 'sm-sidebar-menu--open': modelValue.value }
]));

const logoVisibleClass = computed((): Class => ([
  { 'sm-sidebar-menu__logo-full--visible': modelValue.value }
]));


watch(
  () => modelValue.value,
  (val: boolean): void => {
    if (!val) {
      isExpandedContentVisible.value = val;
      return;
    }
    setTimeout(() => {
      isExpandedContentVisible.value = val;
    }, 250);
  },
);

// Methods

const getTooltipText = (item: ISidebarItem) => {
  const withoutTooltip = modelValue.value || !item.title;

  if (withoutTooltip) return '';

  return item.title;
}

const handleToggleMenu = (): void => {
  emits('update:modelValue', !modelValue.value);
}

const handleCloseMenu = (): void => {
  if (!closeAfterTransition.value) return;

  emits('update:modelValue', false);
}

const getLinkClassList = (itemRoot?: string): Class => {
  const classList = [
    'sm-sidebar-menu__link',
  ];

  if (!itemRoot) return classList;

  const isActive = route.path.includes(itemRoot);
  if (isActive) {
    classList.push('sm-sidebar-menu__link--active')
  }

  return classList;
}
</script>

<style lang='scss'>
.sm-sidebar-menu {
  position: relative;
  background-color: var(--Surface);
  border-right: 1px solid var(--BorderMinor);
  height: 100vh;
  width: 62px;
  padding-top: var(--gap);
  display: flex;
  flex-direction: column;
}

.sm-sidebar-menu--open {
  width: 196px;
}

.sm-sidebar-menu__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.sm-sidebar-menu__logo-full {
  overflow: hidden;
  max-height: 0;
  max-width: 0;
}

.sm-sidebar-menu__logo svg g path {
  fill: var(--Primary);
}

.sm-sidebar-menu__logo svg g .minor {
  fill: var(--Minor);
}

.sm-sidebar-menu__logo-full--visible {
  max-height: unset;
  max-width: 195px;
}

.sm-sidebar-menu__toggle-button {
  background-color: transparent;
  box-shadow: none;
  width: 61px;
  margin-top: var(--gap);
}

.sm-sidebar-menu__toggle-button .sm-button__icon {
  transform: rotate(0deg);
}

.sm-sidebar-menu--open .sm-sidebar-menu__toggle-button .sm-button__icon {
  transform: rotate(180deg);
}

.sm-sidebar-menu__list-wrapper {
  max-height: 100%;
  margin-top: var(--gap);
  padding-bottom: var(--gap);
}

.sm-sidebar-menu__list-wrapper::-webkit-scrollbar {
  width: 3px;
}

.sm-sidebar-menu__list-wrapper::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: var(--PrimaryPress);
}

.sm-sidebar-menu__list {
  margin: 0;
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.sm-sidebar-menu__item {
  width: 100%;
}

.sm-sidebar-menu__link {
  padding: 12px 8px 12px 0;
  background-color: transparent;
  border-left: 4px solid transparent;
  max-width: 61px;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    background-color: var(--SelectionPrimary);
  }
}

.sm-sidebar-menu--open .sm-sidebar-menu__link {
  max-width: 195px;
}

.sm-sidebar-menu__link-icon {
  color: var(--Icon);
  width: 62px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sm-sidebar-menu__link-icon svg {
  width: 16px;
  height: 16px;
}

.sm-sidebar-menu__link-title {
  @include mini;

  overflow: hidden;
  max-height: 0;
  
  color: var(--Title);

  @include transition(('max-height'));
}

.sm-sidebar-menu__link-title--visible {
  max-height: 100px;
}

.sm-sidebar-menu__link-title--no-icon {
  padding-left: var(--padding-s);
  max-height: 100px;
}

.sm-sidebar-menu__link.sm-sidebar-menu__link--active {
  border-color: var(--Primary);
}

.sm-sidebar-menu__link.sm-sidebar-menu__link--active .sm-sidebar-menu__link-icon,
.sm-sidebar-menu__link.sm-sidebar-menu__link--active .sm-sidebar-menu__link-title {
  color: var(--Primary);
}

.sm-sidebar-menu,
.sm-sidebar-menu__logo--visible,
.sm-sidebar-menu__link,
.sm-sidebar-menu__toggle-button-wrapper,
.sm-sidebar-menu__toggle-button .sm-button__icon {
  transition: all 0.5s ease;
}

.sm-sidebar-menu__toggler,
.sm-sidebar-menu__toggle-icon,
.sm-sidebar-menu__link-title__visible {
  transition: all 0.15s ease;
}
</style>