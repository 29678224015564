import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import '@/assets/style/main.scss';

import { clickOutside } from '@/directives/сlickOutside';
import SmDialog from '@/plugins/SmDialog';
import dayjs from '@/plugins/dayjs';
import tinymce from '@/plugins/tinymcePlugin';
import notify from '@/plugins/notify';

createApp(App)
  .use(router)
  .use(clickOutside)
  .use(SmDialog)
  .use(dayjs)
  .use(tinymce)
  .use(notify)
  .mount('#app')
