<template>
  <sm-app-preloader v-model="isLoadingApp" />

  <router-view />

  <sm-notify-bar></sm-notify-bar>
</template>

<script lang="ts" setup>
// Components
import SmAppPreloader from '@/components/common/loaders/SmAppPreloader.vue';
import SmNotifyBar from '@/components/common/notify/SmNotifyBar.vue';

import { setCSSStyles } from './utils/setCSSStyles';

setCSSStyles();
</script>
