<template>
  <div class="sm-auth">
    <header class="sm-auth__header">
      <logo-full />
    </header>
    <main class="sm-auth__main">
      <router-view />
    </main>
    <sm-theme-switcher :app-theme="appTheme" @toggleTheme="toggleTheme"/>
  </div>
</template>

<script lang="ts" setup>
import SmThemeSwitcher from '@/components/common/SmThemeSwitcher.vue';

// Assets
import LogoFull from '@/assets/logo/LogoFullBig.vue';
import { useTheme } from '@/composables/useTheme';

const { appTheme, toggleAppTheme } = useTheme();

const toggleTheme = (): void => {
  toggleAppTheme();
}

</script>

<style lang="scss" scoped>
.sm-auth {
  padding: 48px var(--padding) var(--padding);
  min-height: 100vh;
  position: relative;

  @media (min-width: 959px) {
    padding: 90px var(--padding) var(--padding);
  }
}

.sm-auth__header {
  text-align: center;
  padding-bottom: 32px;
}
.sm-auth__main {
  margin: 0 auto;
  max-width: 558px;
}
</style>
