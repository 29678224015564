import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import UIKitLayout from '@/layouts/UIKitLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    meta: {
      title: 'Auth'
    },
  },
  {
    path: '/ui-kit',
    name: 'UIKit',
    component: UIKitLayout,
    meta: {
      title: 'UI Kit'
    },
    children: [
      {
        path: 'colors',
        name: 'UIKit.Colors',
        component: () => import('../views/uiKit/ColorsView.vue'),
        meta: {
          title: 'Цвета'
        },
      },
      {
        path: 'typography',
        name: 'UIKit.Typography',
        component: () => import('../views/uiKit/TypographyView.vue'),
        meta: {
          title: 'Типографика'
        },
      },
      {
        path: 'icons',
        name: 'UIKit.Icons',
        component: () => import('../views/uiKit/IconsView.vue'),
        meta: {
          title: 'Иконки'
        },
      },
      {
        path: 'buttons',
        name: 'UIKit.Buttons',
        component: () => import('../views/uiKit/ButtonsView.vue'),
        meta: {
          title: 'Кнопки'
        },
      },
      {
        path: 'float',
        name: 'UIKit.Float',
        component: () => import('../views/uiKit/FloatView.vue'),
        meta: {
          title: 'Высплывающие элементы'
        },
        children: [
          {
            path: 'modals',
            name: 'UIKit.Float.Modal',
            component: () => import('../views/uiKit/float/ModalsView.vue'),
            meta: {
              title: 'Модальные окна'
            }
          },
          {
            path: 'dropdown',
            name: 'UIKit.Float.Dropdown',
            component: () => import('../views/uiKit/float/DropdownView.vue'),
            meta: {
              title: 'Выпадающие элементы'
            }
          },
          {
            path: 'tooltips',
            name: 'UIKit.Float.Tooltip',
            component: () => import('../views/uiKit/float/TooltipsView.vue'),
            meta: {
              title: 'Подсказки'
            }
          },
          {
            path: 'notifications',
            name: 'UIKit.Float.Notify',
            component: () => import('../views/uiKit/float/NotificationsView.vue'),
            meta: {
              title: 'Уведомления'
            }
          },
        ]
      },
      {
        path: 'misc',
        name: 'UIKit.Misc',
        component: () => import('../views/uiKit/MiscView.vue'),
        meta: {
          title: 'Прочее'
        },
      },
      {
        path: 'inputs',
        name: 'UIKit.Inputs',
        component: () => import('../views/uiKit/InputsView.vue'),
        meta: {
          title: 'Поля ввода'
        },
        children: [
          {
            path: 'text-input',
            name: 'UIKit.Inputs.TextInput',
            component: () => import('../views/uiKit/inputs/TextInputView.vue'),
            meta: {
              title: 'Текстовое поле'
            }
          },
          {
            path: 'password-input',
            name: 'UIKit.Inputs.PasswordInput',
            component: () => import('../views/uiKit/inputs/PasswordInputView.vue'),
            meta: {
              title: 'Поле пароля'
            }
          },
          {
            path: 'email-input',
            name: 'UIKit.Inputs.EmailInput',
            component: () => import('../views/uiKit/inputs/EmailInputView.vue'),
            meta: {
              title: 'Поле Email'
            }
          },
          {
            path: 'phone-input',
            name: 'UIKit.Inputs.PhoneInput',
            component: () => import('../views/uiKit/inputs/PhoneInputView.vue'),
            meta: {
              title: 'Поле телефона'
            }
          },
          {
            path: 'numeric-input',
            name: 'UIKit.Inputs.NymericInput',
            component: () => import('../views/uiKit/inputs/NumericInputView.vue'),
            meta: {
              title: 'Числовое поле'
            }
          },
          {
            path: 'checkbox',
            name: 'UIKit.Inputs.Checkbox',
            component: () => import('../views/uiKit/inputs/CheckboxView.vue'),
            meta: {
              title: 'Флаг'
            }
          },
          {
            path: 'switcher',
            name: 'UIKit.Inputs.Switch',
            component: () => import('../views/uiKit/inputs/SwitchView.vue'),
            meta: {
              title: 'Переключатель'
            }
          },
          {
            path: 'colorpicker',
            name: 'UIKit.Inputs.Colorpicker',
            component: () => import('../views/uiKit/inputs/ColorpickerView.vue'),
            meta: {
              title: 'Выбор цвета'
            }
          },
          {
            path: 'textarea',
            name: 'UIKit.Inputs.Textarea',
            component: () => import('../views/uiKit/inputs/TextareaView.vue'),
            meta: {
              title: 'Область текста'
            }
          },
          {
            path: 'datepicker',
            name: 'UIKit.Inputs.Datepicker',
            component: () => import('../views/uiKit/inputs/DatepickerView.vue'),
            meta: {
              title: 'Выбор даты'
            }
          },
          {
            path: 'file-input',
            name: 'UIKit.Inputs.FileInput',
            component: () => import('../views/uiKit/inputs/FileInputView.vue'),
            meta: {
              title: 'Выбор файла'
            }
          },
          {
            path: 'select',
            name: 'UIKit.Inputs.Select',
            component: () => import('../views/uiKit/inputs/SelectView.vue'),
            meta: {
              title: 'Выбор'
            }
          },
          {
            path: 'html-editor',
            name: 'UIKit.Inputs.HtmlEditor',
            component: () => import('../views/uiKit/inputs/HtmlEditorView.vue'),
            meta: {
              title: 'HTML-редактор'
            }
          },
        ]
      },
      {
        path: 'layout',
        name: 'UIKit.Layout',
        component: () => import('../views/uiKit/LayoutView.vue'),
        meta: {
          title: 'Раскладка'
        },
        children: [
          {
            path: 'menu',
            name: 'UIKit.Layout.Menu',
            component: () => import('../views/uiKit/LayoutView.vue'),
            meta: {
              title: 'Меню'
            },
          },
          {
            path: 'audit',
            name: 'UIKit.Layout.Audit',
            component: () => import('../views/uiKit/LayoutView.vue'),
            meta: {
              title: 'Аудит'
            },
          },
          {
            path: 'request-dispatcher',
            name: 'UIKit.Layout.RequestDispatcher',
            component: () => import('../views/uiKit/LayoutView.vue'),
            meta: {
              title: 'Диспетчер заявок'
            },
          },
          {
            path: 'dashboard',
            name: 'UIKit.Layout.Dashboard',
            component: () => import('../views/uiKit/LayoutView.vue'),
            meta: {
              title: 'Дашборд'
            },
          },
          {
            path: 'oszn',
            name: 'UIKit.Layout.Oszn',
            component: () => import('../views/uiKit/LayoutView.vue'),
            meta: {
              title: 'ОСЗН'
            },
          },
        ]
      },      
      {
        path: 'generators',
        name: 'UIKit.Generators',
        component: () => import('../views/uiKit/GeneratorsView.vue'),
        meta: {
          title: 'Генераторы'
        },
        children: [
          {
            path: 'forms',
            name: 'UIKit.Generators.Forms',
            component: () => import('../views/uiKit/generators/FormsView.vue'),
            meta: {
              title: 'Генератор форм'
            }
          },
          {
            path: 'reports',
            name: 'UIKit.Generators.Reports',
            component: () => import('../views/uiKit/generators/ReportView.vue'),
            meta: {
              title: 'Отчет'
            }
          },
        ]
      },
    ]
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
